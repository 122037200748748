.container {
    text-align: center;
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.container p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
}

.projectsButton {
    display: inline-block;
    background-color: #007bff;
    color: white;
    font-weight: 400;
    padding: 15px 65px;
    font-size: 1.2rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
}

.projectsButton:hover {
    background-color: #0056b3;
}