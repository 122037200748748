.footer {
    background-color: black;
    color: white;
    padding: 2rem;
}
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-left: 5rem;
    margin-right: 5rem;
}
.end {
    text-align: center;
}
.links a {
    margin-top: 1rem;
    margin-right: 1rem;
}
.row h4 {
    margin-bottom: 2rem;
}