.container {
    margin-top: 3rem;
}

.text {
    color: #666;
}

.customHr {
    height: 8px;
    width: 50px;
    background-color: #007bff;
    border-radius: 50px;
}

.form {
    width: 75%;
}

@media (min-width: 576px) {
    .form {
        width: 50%;
    }
}