.container {
    margin-top: 3rem;
}

.text {
    color: #666;
}

.customHr {
    height: 8px;
    width: 50px;
    background-color: #007bff;
    border-radius: 50px;
}

.skillBox {
    display: inline-block;
    padding: 5px 15px;
    color: #666;
    background-color: #e0e0e0;
    border-radius: 6px;
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
}